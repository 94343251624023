/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'comment': {
    width: 15,
    height: 13,
    viewBox: '0 0 15 13',
    data: '<path pid="0" d="M7.75 1c3.314 0 6.6 2.552 6.6 5.7s-3.286 5.7-6.6 5.7a7.087 7.087 0 01-3.035-.705c-1.881.662-4.35.845-3.565.175.6-.512 1.171-.887 1.672-1.488C1.803 9.389 1.15 8.104 1.15 6.7c0-3.148 3.286-5.7 6.6-5.7z" _stroke="#005C56" _fill="none" fill-rule="evenodd"/>'
  }
})
